import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import Layout from '../components/layout';
import { ContactData, ZendeskRequestForm } from '../components/zendesk-request';

type FormData = {
  subject: string;
  body: string;
  name: string;
  email: string;
};

const ContactForm = (post: (data: ContactData) => void): JSX.Element => {
  const [isAgree, setIsAgree] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormData>();

  const onSubmit = handleSubmit(({
    subject, body, name, email,
  }) => {
    const tags = ['official-website'];
    post({
      request: {
        requester: {
          name,
          email,
        },
        subject,
        tags,
        comment: {
          body,
        },
      },
    });
  });

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <form onSubmit={onSubmit}>
      <div className="field">
        <label className="label">件名</label>
        <div className="control">
          <input
            name="subject"
            className={`input${errors.subject ? ' is-danger' : ''}`}
            type="text"
            ref={register({ required: true, maxLength: 64 })}
          />
        </div>
        { errors.subject && <p className="help is-danger">件名を入力してください。文字数が多過ぎるとエラーになります。</p> }
      </div>

      <div className="field">
        <label className="label">内容</label>
        <div className="control">
          <textarea
            name="body"
            className={`textarea${errors.body ? ' is-danger' : ''}`}
            rows={10}
            ref={register({ required: true, maxLength: 4000 })}
          />
        </div>
        { errors.body && <p className="help is-danger">内容を入力してください。文字数が多過ぎるとエラーになります。</p> }
      </div>

      <div className="field">
        <label className="label">お名前</label>
        <div className="control">
          <input
            name="name"
            className={`input${errors.name ? ' is-danger' : ''}`}
            type="text"
            ref={register({ required: true, maxLength: 64 })}
          />
        </div>
        { errors.name && <p className="help is-danger">お名前を入力してください。文字数が多過ぎるとエラーになります。</p> }
      </div>

      <div className="field">
        <label className="label">メールアドレス</label>
        <div className="control">
          <input
            name="email"
            className={`input${errors.email ? ' is-danger' : ''}`}
            type="email"
            ref={register({ required: true, pattern: /[^@]+@[^@]+/ })}
          />
        </div>
        { errors.email && <p className="help is-danger">メールアドレスを入力してください。</p> }
      </div>

      <div className="field">
        <div className="control">
          <label className="checkbox">
            <p>
              <input type="checkbox" onChange={(event) => setIsAgree(event.target.checked)} />
              {' '}
              <Link to="/privacy-policy">プライバシーポリシー</Link>
              を確認しました。
            </p>
          </label>
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button
            type="submit"
            disabled={!isAgree}
            className="button is-primary"
          >
            送信
          </button>
        </div>
      </div>
    </form>
  );
  /* eslint-enable jsx-a11y/label-has-associated-control */
};

const ContactDone = (): JSX.Element => (
  <p>お問い合わせを送信しました。</p>
);

const Contact = (): JSX.Element => (
  <Layout seo={{ title: 'Contact' }}>
    <section className="section">
      <div className="container">
        <h1 className="title">Contact</h1>
        <ZendeskRequestForm
          formComponent={ContactForm}
          doneComponent={ContactDone}
        />
      </div>
    </section>
  </Layout>
);

export default Contact;

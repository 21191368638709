import axios from 'axios';
import React from 'react';

export type ContactData = {
  request: {
    requester: {
      name: string;
      email: string;
    };
    subject: string;
    tags: string[];
    comment: {
      body: string;
    };
  };
};

async function requestZendesk(data: ContactData): Promise<boolean> {
  const zendeskHost = 'https://re-arc-lab.zendesk.com';
  const requestUrl = `${zendeskHost}/api/v2/requests.json`;

  const url = requestUrl;
  const response = await axios.post<{
    request: {
      id: number;
    };
  }>(url, data);
  // 200 range
  return response.status >= 200 && response.status < 300;
}

type Props = {
  formComponent: (post: (data: ContactData) => void) => JSX.Element;
  doneComponent: () => JSX.Element;
};
export class ZendeskRequestForm extends React.Component<Props, { submit: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { submit: false };
  }

  Form = (): JSX.Element => {
    const { formComponent } = this.props;
    return formComponent(this.post);
  };

  Done = (): JSX.Element => {
    const { doneComponent } = this.props;
    return doneComponent();
  };

  render = (): JSX.Element => {
    const { submit } = this.state;
    return !submit ? (<this.Form />) : (<this.Done />);
  };

  post = (data: ContactData): void => {
    this.postAsync(data);
  };

  postAsync = async (data: ContactData): Promise<void> => {
    const response = await requestZendesk(data);
    if (response) {
      this.setSubmit(true);
    }
  };

  setSubmit = (submit: boolean): void => {
    this.setState((previous) => ({
      ...previous,
      submit,
    }));
  };
}
